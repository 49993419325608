import { AutomatitCarousel } from './automatit_carousel.m';

export const initHeroCaro = () => {
	AutomatitCarousel({
		element: document.querySelector('#home_hero'),
		images: [
			{src: "/static/images/background/img_hero.jpg", alt: ""},
			{src: "/static/images/background/img_hero2.jpg", alt: ""},
			{src: "/static/images/background/img_hero3.jpg", alt: ""},
			{src: "/static/images/background/img_hero4.jpg", alt: ""},
		],
		imagesAsBackgrounds: true,
		useChevrons: false,
		showDots: true,
	});
};