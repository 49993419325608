import { getMapsHelper } from './util/mapsHelper';
export const locationsMap = () => {
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			mapHelper.createMap({
				element: document.querySelector('#locations_map'),
				locationElementSelector: '.locations_list_item',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `<a target="_blank" href="${el.getAttribute('link')}" class="map_pin"><img src="/static/images/icons/map_pin.svg" alt="" /></a>`
					};
				}
			});
		});
};