import { initMobileNav, initDoubleClick } from './header';
import { contactSubmit, initContactMap } from './contact.js';
import { facilityCaro, facilityMap, facilityInfo, rateFilters, smoothScroll } from './facility';
import { initHeroCaro } from './home';
import { locationsMap } from './locations';
import { initReviewsCaro } from './reviewsCaro';

Element.prototype.switchAttribute = function(attribute, val1, val2) {
	this.setAttribute(attribute, (this.getAttribute(attribute) === val1 ? val2 : val1));
};

initMobileNav();

if(document.querySelector('#home_hero')) {
	initHeroCaro();
}

if(document.querySelector('#inc_reviews_caro')) {
	initReviewsCaro();
}

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'facility', 'email', 'message']
	});
}

// Facility Carousel
if(document.querySelector('#facility_carousel')) {
	facilityCaro();
}
// Map
if(document.getElementById('map')){
	facilityMap();
}
// Facility Mobile toggle
if(document.getElementById('facility_mobile_info')){
	facilityInfo();
}
if(document.querySelector('#rates_filter_wrap')) {
	rateFilters();
}
if(document.querySelector('#rates_nav_wrap')) {
	smoothScroll();
}
if(document.querySelector('#locations_map')) {
	locationsMap();
}

if(document.querySelector('#contact_map')) {
	initContactMap();
}
// Double tippy tappy
if(document.querySelector('.d_click')) {
	initDoubleClick();
}